<template>
  <div>
    <section class="py-3 px-5 page-banner">
      <router-link to="/cities" class="go-back-link"
        ><BIconArrowLeftShort />Back to cities</router-link
      >
      <h3 class="page-header py-3">
        {{ isEditPage ? cityDetails.cityName : "Add city" }}
      </h3>
      <b-row class="justify-content-between">
        <b-col cols="12" class="col-sm-6">
          <b-nav role="navigation">
            <b-nav-item to="general" exact exact-active-class="active"
              >General</b-nav-item
            >
            <b-nav-item
              v-if="isEditPage"
              to="widget-setup"
              exact
              exact-active-class="active"
              >Widget setup</b-nav-item
            >
            <b-nav-item
              v-if="isEditPage"
              to="address-setup"
              exact
              exact-active-class="active"
              >Address setup</b-nav-item
            >
            <b-nav-item
              v-if="isEditPage"
              to="security"
              exact
              exact-active-class="active"
              >Security</b-nav-item
            >
          </b-nav>
        </b-col>
        <b-col cols="12" class="col-sm-6 text-right mt-n4">
          <!-- <b-button type="button" v-activeBlur class="transparent mr-3">Preview</b-button> -->
        </b-col>
      </b-row>
    </section>
    <section class="py-4 px-5">
      <LoaderIcon v-if="isEditPage && cityDetailsLoading" />
      <router-view
        v-else
        :cityDetails="cityDetails"
        :isEditPage="isEditPage"
        :isSettingsPage="false"
        @resetCityDetails="resetCityDetails"
        @updateIsEditPage="isEditPage = $event"
      ></router-view>
    </section>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import LoaderIcon from '../../assets/svg/loader.svg'
import { BIconArrowLeftShort } from 'bootstrap-vue'

export default {
  name: 'AddEditCity',
  data () {
    return {
      isEditPage: false,
      cloneCityDetails: null,
      cityDetails: {
        id: 0,
        cityName: null,
        tenantServiceId: null,
        appName: null,
        hostName: null,
        favIconLnk: null,
        shortcutIconLnk: null,
        logoColorFile: null,
        logoColorURI: null,
        logoBannerFile: null,
        logoBannerURI: null,
        topHeaderColor: null,
        headerTextColor: null,
        buttonColor: null,
        notifyIconBackGrndColor: null,
        usrProfileIconBackGrndColor: null,
        notifyIconColor: null,
        usrProfileIconColor: null,
        switchPropBtnBackGrndColor: null,
        switchPropBtnTextColor: null,
        onBoardingBackGrndColor: null,
        onBoardingColor: null,
        bannerTextColor: null,
        backgroundOnBoardingFile: null,
        backgroundOnBoardingURI: null,
        loginBackGrndScreenFile: null,
        loginBackGrndScreenURI: null,
        socialMediaSignUpLst: [],
        onBoardingTitle: null,
        onBoardingTxt: null,
        footerTxt: null,
        bannerOptionSelected: 'color',
        bannerBackGrndImageFile: null,
        bannerBackGrndImageURI: null,
        bannerBackGrndColor: null,
        termsConditionSelected: 'link',
        termsConditionLnk: null,
        termsConditionContent: null,
        privacyPolicySelected: 'content',
        privacyPolicyLnk: null,
        privacyPolicyContent: null,
        siteBackText: '',
        siteBackLink: ''
      },
      cityDetailsLoading: false
    }
  },
  components: {
    LoaderIcon,
    BIconArrowLeftShort
  },
  created () {
    if (
      this.$route.name === 'Edit-general-city' ||
      this.$route.name === 'City-widget-setup' ||
      this.$route.name === 'City-address-setup' ||
      this.$route.name === 'City-security'
    ) {
      this.isEditPage = true
      this.getCityDetails()
    }
  },
  mounted () {
    this.cloneCityDetails = cloneDeep(this.cityDetails)
  },
  methods: {
    getCityDetails () {
      this.cityDetailsLoading = true
      this.$store
        .dispatch('cities/getTenantSettings', this.$route.params.tenantID)
        .then((response) => {
          this.cityDetailsLoading = false
          response.data.bannerOptionSelected = response.data
            .bannerBackGrndColor
            ? 'color'
            : 'image'
          response.data.termsConditionSelected = response.data
            .termsConditionContent
            ? 'content'
            : 'link'
          response.data.privacyPolicySelected = response.data.privacyPolicyLnk
            ? 'link'
            : 'content'
          response.data.siteBackText = response.data.siteBackText
            ? response.data.siteBackText
            : ''
          this.cityDetails = response.data
          this.cloneCityDetails = cloneDeep(response.data)
        })
        .catch(() => {
          this.cityDetailsLoading = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger'
          })
        })
    },
    resetCityDetails () {
      this.cityDetails = cloneDeep(this.cloneCityDetails)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
